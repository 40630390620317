import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jackets',
  templateUrl: './jackets.component.html',
  styleUrls: ['./jackets.component.css']
})
export class JacketsComponent implements OnInit {


  designs = [
    { "image": "../../../assets/jk/1.jpeg" },
    { "image": "../../../assets/jk/2.jpeg" },
    { "image": "../../../assets/jk/3.jpeg" },
    { "image": "../../../assets/jk/4.jpeg" },
    { "image": "../../../assets/jk/5.jpeg" },
    { "image": "../../../assets/jk/6.jpeg" },
    { "image": "../../../assets/jk/7.jpeg" },
    { "image": "../../../assets/jk/8.jpeg" },
    { "image": "../../../assets/jk/9.jpeg" },
    { "image": "../../../assets/jk/10.jpeg" },
    { "image": "../../../assets/jk/11.jpeg" },
    { "image": "../../../assets/jk/12.jpeg" },
    { "image": "../../../assets/jk/13.jpeg" },
    { "image": "../../../assets/jk/14.jpeg" },
    { "image": "../../../assets/jk/15.jpeg" },
];


  constructor() { }

  ngOnInit(): void {
  }

}
