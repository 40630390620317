<div class="row">


    <div>
        <h3 style="float: center;">Nuevo Pedido</h3>
        <br>
        <form [formGroup]="registerForm" (ngSubmit)="registerData(registerForm)">
            <h6><strong>Datos Cliente</strong></h6>
            <div class="row">
                <div class="form-group col-4">
                    <label for="inputState">Cliente</label>
                    <select [attr.disabled]="type" (change)="change($event.target.value)" id="inputState" class="form-control" formControlName="customer">
                        <option value="" selected>Seleccione...</option>
                        <option *ngFor="let customer of customers" value="{{customer.name}}">{{customer.name}}</option>
                    </select>
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Correo</label>
                    <input readonly type="text" class="form-control" placeholder="Correo" formControlName="email">
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Telefono</label>
                    <input readonly type="text" class="form-control" placeholder="Telefono" formControlName="phone">
                </div>
            </div>
            <!-- COLORES -->
            <hr class="text-primary">
            <h6><strong>Modelo o Referencia</strong></h6>
            <div class="row">
                <div class="form-group col-6">
                    <label for="inputState">Referencia</label>
                    <select id="inputState" class="form-control" formControlName="model" (change)="changemodel($event.target.value)" >
                        <option value="" selected>Seleccione...</option>
                        <option  *ngFor="let model of models" value="{{model.name}}">{{model.name}}</option>                        
                    </select>
                </div>
                <div class="form-group col-2"></div>
                <div class="form-group col-4">
                    <label for="inputState"><strong>Imagen de Referencia</strong></label><br>
                    <p>{{des}}</p>
                    <img style="max-width: 200px;max-height: 200px;" src="{{photo}}"
                        alt="">
                </div>
            </div>


            <!-- COLORES -->
            <hr class="text-primary">
            <h6><strong>Colores de La Chaqueta</strong></h6>
            <div class="row">
                <div class="form-group col-12">
                    <label for="inputState">Color Principal</label>
                    <select class="form-control" formControlName="colorpri">
                        <option value="" selected>Seleccione...</option>
                        <option value="rojo">Rojo</option>
                        <option value="azul">Azul</option>
                        <option value="verde">Verde</option>
                        <option value="amarillo">Amarillo</option>
                        <option value="naranja">Naranja</option>
                        <option value="negro">Negro</option>
                        <option value="blanco">Blanco</option>
                        <option value="gris">Gris</option>
                        <option value="morado">Morado</option>
                        <option value="rosa">Rosa</option>
                    </select>
                </div>
                <p>Color Mas Visible en la chaqueta</p>
            
                <div class="form-group col-12">
                    <label for="inputState">Color Secundario</label>
                    <select class="form-control" formControlName="colorsec">
                        <option value="" selected>Seleccione...</option>
                        <option value="rojo">Rojo</option>
                        <option value="azul">Azul</option>
                        <option value="verde">Verde</option>
                        <option value="amarillo">Amarillo</option>
                        <option value="naranja">Naranja</option>
                        <option value="negro">Negro</option>
                        <option value="blanco">Blanco</option>
                        <option value="gris">Gris</option>
                        <option value="morado">Morado</option>
                        <option value="rosa">Rosa</option>
                    </select>
                </div>
                <p>Color Visible en la chaqueta</p>
            
                <div class="form-group col-12">
                    <label for="inputState">Color Terciario (opcional)</label>
                    <select class="form-control" formControlName="colorter">
                        <option value="" selected>Seleccione...</option>
                        <option value="rojo">Rojo</option>
                        <option value="azul">Azul</option>
                        <option value="verde">Verde</option>
                        <option value="amarillo">Amarillo</option>
                        <option value="naranja">Naranja</option>
                        <option value="negro">Negro</option>
                        <option value="blanco">Blanco</option>
                        <option value="gris">Gris</option>
                        <option value="morado">Morado</option>
                        <option value="rosa">Rosa</option>
                        <option value="reflectivo">Reflectivo</option>
                    </select>
                </div>
                <p>Color Menos Visible en la chaqueta</p>
            </div>
            
            <!-- COLORES -->
            <hr class="text-primary">
            <h6><strong>Tallaje o Medidas</strong></h6>
            <div class="row">
                <div class="form-group col-12">
                    <label for="inputState">Tallaje o Medidas</label>
                    <select formControlName="types" id="inputState" class="form-control" (change)="change1($event.target.value)">
                        <option value="" selected>Seleccione...</option>
                        <option value="Tallaje">Tallaje</option>
                        <option value="Medidas">Medidas</option>
                        <option value="Curva">Curva</option>
                    </select>
                </div>

                <!--tallas-->
                <div *ngIf="tallas" class="row">
                    <div class="form-group col">
                        <label for="inputState">Tallas</label>
                        <select id="inputState" class="form-control" formControlName="talla">
                            <option value="" selected>Seleccione...</option>
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                            <option value="32">32</option>
                            <option value="34">34</option>
                            <option value="36">36</option>
                            <option value="38">38</option>
                            <option value="40">40</option>
                        </select>
                    </div>
                    <div class="form-group col">
                        <label for="inputState">Cantidad</label>
                        <input type="number" class="form-control" placeholder="Cantidad" formControlName="cant">
                    </div>
                </div>
                <!--MEDIDAS-->
                <div *ngIf="medidas" class="row">
                    <div class="form-group col-6">
                        <label for="inputState">Hombro CM</label>
                        <input type="number" class="form-control" placeholder="Hombro CM" formControlName="hombro">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Brazo CM</label>
                        <input type="number" class="form-control" placeholder="Brazo CM" formControlName="brazo">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Pecho CM</label>
                        <input type="number" class="form-control" placeholder="Pecho CM" formControlName="pecho">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Contorno - Cintura CM</label>
                        <input type="number" class="form-control" placeholder="Contorno - Cintura CM" formControlName="contorno">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Largo Frente</label>
                        <input type="number" class="form-control" placeholder="Largo Frente" formControlName="largo">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Cadera - Damas (Opcional)</label>
                        <input type="number" class="form-control" placeholder="Cadera Damas" formControlName="cadera">
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Cantidad</label>
                        <input type="number" class="form-control" placeholder="Cantidad" formControlName="cant">
                    </div>
                </div>
            </div>



            <!-- LOGOS -->
            <hr class="text-primary">
            <h6><strong>Logos</strong></h6>
                        <!--PERSONZIZADO-->             
                        <div class="form-group col">
                            <label for="inputState">Logo o Diseño</label>
                            <select id="inputState" class="form-control" formControlName="logos" (change)="change2($event.target.value)">
                                <option value="" selected>Seleccione...</option>
                                <option value="Personalizado">Personalizado</option>
                                <option value="Alpinestar">Alpinestar</option>
                                <option value="Icon">Icon</option>
                                <option value="Fox">Fox</option>
                                <option value="KTM">KTM</option>
                                <option value="Kawasaki">Kawasaki</option>
                                <option value="Ducati">Ducati</option>
                                <option value="Monster">Monster</option>
                                <option value="Honda">Honda</option>
                                <option value="TVS">TVS</option>
                                <option value="AKT">AKT</option>
                                <option value="Dainese">Dainese</option>
                                <option value="Pulsar">Pulsar</option>
                                <option value="VR46">VR46</option>
                                <option value="Yamaha">Yamaha</option>
                                <option value="Suzuki">Suzuki</option>
                                <option value="Apache">Apache</option>
                                <option value="Pantera">Pantera</option>
                                <option value="AGV">AGV</option>
                                <option value="RedBull">RedBull</option>
                                <option value="TheDoctor">TheDoctor</option>
                            </select>
                        </div>
            <div *ngIf="verlogos">
                <div class="row">
                    <div class="form-group col-6">
                        <label for="inputState">Logo Jiba</label>
                        <select id="inputState" class="form-control" formControlName="logojiba">
                            <option value="" selected>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Adjuntar Imagen Jiba</label>
                        <img style="max-width: 10%;" src="{{logo1}}" alt="">
                        <input (change)="uploadFile1($event)" type="file" class="form-control" placeholder="Adjunto" formControlName="logojibaimagen">
                    </div>
                    <!---->
                    <div class="form-group col-6">
                        <label for="inputState">Logo Manga</label>
                        <select id="inputState" class="form-control" formControlName="logomanga">
                            <option value="" selected>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Adjuntar Imagen Manga</label>
                        <img style="max-width: 10%;" src="{{logo2}}" alt="">
                        <input (change)="uploadFile2($event)" type="file" class="form-control" placeholder="Adjunto" formControlName="logomangaimagen">
                    </div>
                    <!---->
                    <div class="form-group col-6">
                        <label for="inputState">Logo Frente</label>
                        <select id="inputState" class="form-control" formControlName="logofrente">
                            <option value="" selected>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Adjuntar Imagen Frente</label>
                        <img style="max-width: 10%;" src="{{logo3}}" alt="">
                        <input (change)="uploadFile3($event)" type="file" class="form-control" placeholder="Adjunto" formControlName="logofrenteimagen">
                    </div>
                    <!---->
                    <div class="form-group col-6">
                        <label for="inputState">Logo Espalda</label>
                        <select id="inputState" class="form-control" formControlName="logoespalda">
                            <option value="" selected>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Adjuntar Imagen Espalda</label>
                        <img style="max-width: 10%;" src="{{logo4}}" alt="">
                        <input (change)="uploadFile4($event)" type="file" class="form-control" placeholder="Adjunto" formControlName="logoespaldaimagen">
                    </div>

                    <!---->
                    <div class="form-group col-6">
                        <label for="inputState">Logo Pantalon</label>
                        <select id="inputState" class="form-control" formControlName="logopantalon">
                            <option value="" selected>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="inputState">Adjuntar Imagen Pantalon</label>
                        <img style="max-width: 10%;" src="{{logo5}}" alt="">
                        <input (change)="uploadFile5($event)" type="file" class="form-control" placeholder="Adjunto" formControlName="logopantalonimagen">
                    </div>
                    
                </div>
            </div>

            <div class="row">
                <!---->
                <div class="form-group col-4">
                    <label for="inputState">Logo RH</label>
                    <select id="inputState" class="form-control" formControlName="logorh">
                        <option value="" selected>Seleccione...</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Escribir RH</label>
                    <input type="text" class="form-control" placeholder="RH" formControlName="logorhtext">
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Ubicacion RH</label>
                    <select id="inputState" class="form-control" formControlName="logorhlocation">
                        <option value="" selected>Seleccione...</option>
                        <option value="Cuello">Cuello</option>
                        <option value="Frente">Frente</option>
                        <option value="Espalda">Espalda</option>
                        <option value="Manga">Manga</option>
                        <option value="Jiba">Jiba</option>
                    </select>
                </div>
                <!---->
                <div class="form-group col-4">
                    <label for="inputState">Logo Nombre</label>
                    <select id="inputState" class="form-control" formControlName="logonombre">
                        <option value="" selected>Seleccione...</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Escribir Nombre</label>
                    <input type="text" class="form-control" placeholder="Nombre" formControlName="logonombretext">
                </div>
                <div class="form-group col-4">
                    <label for="inputState">Ubicacion Nombre</label>
                    <select id="inputState" class="form-control" formControlName="logonombrelocation">
                        <option value="" selected>Seleccione...</option>
                        <option value="Cuello">Cuello</option>
                        <option value="Frente">Frente</option>
                        <option value="Espalda">Espalda</option>
                        <option value="Manga">Manga</option>
                        <option value="Jiba">Jiba</option>
                    </select>
                </div>
            </div>


            <!-- COLORES -->
            <hr class="text-primary">
            <h6><strong>Adicionales</strong></h6>
            <div class="row">
                <label for="inputState">Observaciones o Comentarios</label>
                <textarea type="text" class="form-control" placeholder="Observaciones" formControlName="observations"></textarea>
            </div>

            <!--GUARDAR-->
            <hr class="text-primary">
            <h6><strong>Guardar</strong></h6>
            <div class="row">
                <label for="inputState">POR FAVOR REVISAR Y CONFIRMAR</label>
                <button [disabled]="disabled" type="submit" class="btn btn-primary">GUARDAR INFORMACION</button>
            </div>
            <br>
        </form>
    </div>



</div>