<div *ngIf="logins" class="login min-vh-100 d-flex align-items-center ">
  <div class="row w-100 vh-100">
    <!-- Columna de la imagen -->
    <div class="col-lg-6 d-none d-md-flex align-items-center justify-content-center bg-light">
      <img class="img-fluid w-100 vh-100" src="../../../assets/img/login/bg-img.jpg" alt="Imagen de fondo">
    </div>
    <!-- Columna del formulario -->
    <div class="col-lg-6 d-flex align-items-center justify-content-center vh-100">
      <div class="vh-100 p-4">
        <div class="text-center mb-4">
          <a href="sisproteck-site">
            <img class="img-fluid w-50 mt-1 pb-3" src="../../../assets/logosis.png" alt="Logo Sisproteck">
          </a>
          <h4 class="text-primary title-form ">Bienvenido al sistema Sisproteck Motos</h4>
        </div>
        <form class="form-login">
          <div class="input-group mb-3">
            <div class="form-floating flex-grow-1">
              <input [(ngModel)]="user" name="user" type="email" id="email" class="form-control" aria-describedby="emailHelp" placeholder="">
                <label for="email" class="light-gray">Usuario</label>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="form-floating flex-grow-1">
              <input name="pass" [(ngModel)]="pass" type="password" id="pass" class="form-control" placeholder="">
                <label for="pass" class="light-gray">Contraseña</label>
            </div>
          </div>
          <button (click)="mostrar()" class="btn btn-primary btn-block w-100 text-xl-center">Iniciar sesión</button>
        </form>

<br>

        <div class="alert" role="alert" style="background-color: orange;">
          <p class="small mb-3" style="text-align: center;color: black;">
            <img src="../../../assets/reflectivos.jpeg" style="width: 50%;align-content: center;" alt="">
            <br>
            <strong>Importante:</strong> Explora nuestra colección de chaquetas reflectivas para motociclistas y destaca en la carretera con elegancia. 
          </p>
          
          <a class="btn btn-primary btn-sm" href="sisproteck-jackets">Ver Más</a>
        </div>
        
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="!logins">
    <hr>
    <div class="col-lg-4"></div>
    <div class="col-lg-4"><button *ngIf="type == 'User' || type == 'Admin'" (click)="verPedidos()" class="btn btn-primary">Nuevo Pedido</button> -- <button *ngIf="type == 'Admin'"
            (click)="verClientes()" class="btn btn-primary">Clientes</button> -- <button *ngIf="type == 'User' || type == 'Admin'" (click)="verListas()"
            class="btn btn-primary">Pedidos</button></div>
    <div class="col-lg-4"><a style="float: right;margin-right: 20px;" href="#">Cerrar sesión</a></div>
</div>
<hr>
<div class="row" *ngIf="!logins" style="margin: 20px;">
    <app-requests *ngIf="pedidos"></app-requests>
    <app-customers *ngIf="clientes"></app-customers>
    <app-lists *ngIf="lists"></app-lists>
</div>


    <!-- Button trigger modal -->
    <a id="modals" type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal">
    </a>
      
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Felices Fiestas</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <img style="width: 100%;" height="50%" src="../../../assets/fiestas.jpeg" alt="">
            </div>
          </div>
        </div>
      </div>
   